span
<template>
  <div class="input-range-wrapper">
    <vue-slider
      v-if="props.isSlider && !props.noMin"
      v-model="sliderRange"
      :max="Math.ceil(max)"
      :tooltip="''"
      :dot-size="10"
      :height="4"
      @drag-end="updateRange"
      @change="updateInput"
      @dragging="handleDragging"
    />
    <vue-slider
      v-if="props.noMin"
      v-model="sliderValueNoMin"
      :max="100"
      :tooltip="''"
      :dot-size="10"
      :height="4"
      @drag-end="updateRange"
      @change="updateInput"
      @dragging="handleDragging"
    />
    <div class="input-range">
      <span class="custom-input">
        <input
          v-model="inputStart"
          type="text"
          :style="{ width: getInputW.min + 'px' }"
          :placeholder="$props.placeholder1"
          @input="
            () => {
              const newVal = onlyNumbers(inputStart);
              $nextTick(() => {
                inputStart = newVal;
              });
            }
          "
          @change="updateByInput"
        />
      </span>
      <base-separator />
      <span v-if="!props.noMin" class="custom-input">
        <input
          v-model="inputEnd"
          :style="{ width: getInputW.max + 'px' }"
          type="text"
          :placeholder="$props.placeholder2"
          @input="
            () => {
              const newVal = onlyNumbers(inputEnd);
              $nextTick(() => {
                inputEnd = newVal;
              });
            }
          "
          @change="updateByInput"
        />
      </span>
      <span v-else class="custom-input">
        <input
          v-model="sliderValueNoMin"
          type="text"
          :placeholder="$props.placeholder2"
          @input="
            () => {
              const newVal = onlyNumbers(inputEnd);
              $nextTick(() => {
                inputEnd = newVal;
              });
            }
          "
          @change="updateByInput"
        />
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onlyNumbers } from "~/utilities/helpers/format-data/onlyNumbers";
import VueSlider from "vue-3-slider-component";
import { filtersStore } from "~/store/search_filters";

interface IProps {
  name: string;
  placeholder1?: string | undefined;
  placeholder2?: string | undefined;
  isSlider?: boolean;
  sliderMax?: number;
  sliderMin?: number;
  noMin?: boolean;
  after?: string;
  dropFunc: () => Function;
}

const emit = defineEmits(["change"]);
const route = useRoute();
const props = defineProps<IProps>();
const isDragging = ref(false);
const useFiltersStore = filtersStore();
const inputStart = ref<number | undefined>(props.sliderMin || 0);
const inputEnd = ref<number | undefined>(props.sliderMax);
const sliderRange = ref<(number | undefined)[]>([
  props.sliderMin || 0,
  props.sliderMax || 100,
]);
const min = ref(0);
const max = ref(0);

const getInputW = computed(() => {
  let min: number;
  let max: number;
  if (!inputStart.value) {
    min = 14;
  } else {
    min = inputStart.value.toString().length * 11;
  }
  if (!inputEnd.value) {
    max = 14;
  } else {
    max = inputEnd.value.toString().length * 11;
  }
  return { min, max };
});

const sliderValueNoMin = ref(100);

const updateInput = () => {
  nextTick(() => {
    inputStart.value = sliderRange.value[0];
    inputEnd.value = sliderRange.value[1];
  });
};

let timeout: ReturnType<typeof setTimeout>;

const updateRange = () => {
  isDragging.value = false;
  timeout = setTimeout(() => {
    if (isDragging.value) {
      clearTimeout(timeout);
      return;
    }
    if (!props.noMin) {
      emit("change", {
        [props.name]: {
          gte: inputStart.value,
          lte: inputEnd.value,
        },
      });
    } else {
      emit("change", {
        [props.name]: {
          lte: sliderValueNoMin.value,
        },
      });
    }
  }, 1500);
};

const updateByInput = () => {
  if (!props.noMin) {
    sliderRange.value[1] = inputStart.value || 0;
    sliderRange.value[0] = inputEnd.value || props.sliderMax;
  }
  updateRange();
};

const dropInputRange = (nums: [number | undefined, number | undefined]) => {
  sliderValueNoMin.value = Math.ceil(props.sliderMax);
  sliderRange.value[0] = Math.ceil(nums[0] || 0);
  inputStart.value = Math.ceil(nums[0] || 0);
  sliderRange.value[1] = Math.ceil(nums[1] || 100);
  inputEnd.value = Math.ceil(nums[1] || 100);
  min.value = inputStart.value;
  max.value = inputEnd.value;
};

const handleDragging = (event: any) => {
  isDragging.value = true;
};

const afterSymbol = computed(() => `"${props.after || "%"}"`);

onMounted(() => {
  const drop = props.dropFunc();
  drop(dropInputRange);
  nextTick(() => {
    const gte =
      typeof route.query[`${props.name}[gte]`] === "string"
        ? route.query[`${props.name}[gte]`]
        : useFiltersStore.savedStats[`${props.name}Min`];
    const lte =
      typeof route.query[`${props.name}[lte]`] === "string"
        ? route.query[`${props.name}[lte]`]
        : useFiltersStore.savedStats[`${props.name}Max`];
    if (!props.noMin) {
      sliderRange.value[0] = inputStart.value = Math.ceil(gte);
      sliderRange.value[1] = inputEnd.value = Math.ceil(lte);
      min.value = Math.ceil(useFiltersStore.savedStats[`${props.name}Min`]);
      max.value = Math.ceil(useFiltersStore.savedStats[`${props.name}Max`]);
    }
  });
});
</script>

<style scoped lang="scss">
.input-range-wrapper {
  display: grid;
  gap: 12px;
  :deep(.vue-slider-process) {
    background: var(--violet-main);
  }
  :deep(.vue-slider) {
    justify-self: center;
    width: 94% !important;
  }
  :deep(.vue-slider-dot-handle) {
    background: var(--violet-main);
    border: 1px solid white;
  }
}
.input-range {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 3px;
  .base-separator {
    width: 7px;
  }
  .custom-input {
    display: flex;
    width: 74px;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    height: 32px;
    border-radius: 6px;
    padding: 6px 8px;
    border: 1px solid var(--Monochrome-Black-60, rgba(18, 18, 18, 0.6));
    background: var(--Monochrome-White, #e9ebf8);
    input {
      height: 100%;
      max-width: 60px;
      min-width: 14px;
      background: none;
      outline: none;
      border: none;
      color: var(--Monochrome-Black-60, rgba(18, 18, 18, 0.6));
      font-family: "DM Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
    &::after {
      content: v-bind(afterSymbol);
      position: relative;
      top: 1px;
      color: var(--Monochrome-Black-60, rgba(18, 18, 18, 0.6));
      font-family: "DM Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }
}
</style>
